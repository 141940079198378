import axios from '../axios.instance'

export default class ModulePermission {

    async getSystemModule(){
        try {
            const res = await axios.get('/system-module')
            // console.log(res)
            return res.data
        } catch (error) {
            return error.response.data;
        }
    }

    async addSystemModule(data) {
        try {
            if(data != ''){
                const res = await axios.post('/system-module', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateSystemModule(module_id, data) {
        try {
            if(data != ''){
                const res = await axios.put('/system-module/'+module_id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteSystemModule(module_id) {
        try {
            if(module_id != 0) {
                const res = await axios.delete('/system-module/'+module_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}
import axios from '../axios.instance'

const CancelToken = axios.CancelToken;
let source = '';

export default class ModulePermission {
    async getModulePermission(roleID){
        try {
            source = CancelToken.source();
            const res = await axios.get('/modulepermission/'+roleID, {
                cancelToken: source.token
            })
            // console.log(res)
            return res.data
        } catch (error) {
            return error.response.data;
        }
    }

    cancelRequest() {
        source.cancel('Request Canceled');
    }

    // async assignPermission(data){
    //     try {
    //         if(data != ''){
    //             const res = await axios.post('/modulepermission/assignpermission', {data})
    //             return res.data;
    //         }
    //     } catch (error) {
    //         return error.response.data
    //     }
    // }

    async updatePermission(data){
        try {
            if(data != ''){
                const res = await axios.put('/modulepermission', { data })
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deletePermission(permission_id){
        try {
            if(permission_id != 0) {
                const res = await axios.delete('/modulepermission/'+permission_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}
<template>
<div class="role-mgr">
    <UserRole :EditRoleButton="!ShowAssignButtonRoleList" :AssignRoleButton="ShowAssignButtonRoleList" @EmitRefreshUserAccount="emitRefreshUser" />

    <div class="content-section implementation">
        <div class="card">
            <div class="permission-datatable">
                <DataTable
                    :value="permission"
                    responsiveLayout="scroll"
                    selectionMode="single"
                    dataKey="module_name"
                    :loading="loading"
                >
                    <Column field="module_id" header="ID" :style="{'min-width':'70px'}"></Column>
                    <Column field="module_name" header="System module" :style="{'min-width':'130px', 'max-width':'230px'}"></Column>
                    <Column field="can_view" header="View" :style="{'min-width':'80px'}">
                        <template #body='slotProps'>
                            <Checkbox v-model="slotProps.data[slotProps.column.props.field]" :binary="true" @change="onCheckBoxChanged(slotProps.data, slotProps.index)" :disabled="userRole.id == 1" />
                        </template>
                    </Column>
                    <Column field="can_create" header="Add" :style="{'min-width':'80px'}">
                        <template #body='slotProps'>
                            <Checkbox v-model="slotProps.data[slotProps.column.props.field]" :binary="true" @change="onCheckBoxChanged(slotProps.data, slotProps.index)" :disabled="userRole.id == 1" />
                        </template>
                    </Column>
                    <Column field="can_update" header="Update" :style="{'min-width':'80px'}">
                        <template #body='slotProps'>
                            <Checkbox v-model="slotProps.data[slotProps.column.props.field]" :binary="true" @change="onCheckBoxChanged(slotProps.data, slotProps.index)" :disabled="userRole.id == 1" />
                        </template>
                    </Column>
                    <Column field="can_delete" header="Delete" :style="{'min-width':'80px'}">
                        <template #body='slotProps'>
                            <Checkbox v-model="slotProps.data[slotProps.column.props.field]" :binary="true" @change="onCheckBoxChanged(slotProps.data, slotProps.index)" :disabled="userRole.id == 1" />
                        </template>
                    </Column>

                    <Column v-if="userIs.type == 'Administrator'" :exportable="false" :style="{'min-width':'50px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                        <template #body="slotProps">
                            <!-- <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="openUserAccountForm('update', slotProps.index)"/> -->
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeletePermission(slotProps.data)" v-if="!ShowAssignButtonRoleList"/>
                        </template>
                    </Column>

                    <template v-if="permission == ''" #footer>
                        <div class="datatable-footer">
                            <i class="pi pi-info-circle p-mr-3" style="font-size: 2rem; color: var(--orange-500);" />
                            <span>This role has no permission assigned yet.</span>
                        </div>
                    </template>
                </DataTable>
                <ConfirmDialog></ConfirmDialog>
            </div>
            
            <div v-if="!ShowAssignButtonRoleList">
                <hr style="border: 0; border-top: 0px solid var(--surface-d);"/>
                <Button v-if="userIs.type == 'Administrator'" label="Edit Module" @click="editModuleDialog()" />
                <Button v-if="userIs.type == 'Administrator'" label="Update Permission" :disabled="!showUpdatePermissionButton" class="p-ml-2 p-button-warning" @click="updatePermission()" />
                <InlineMessage class="p-ml-2" v-if="showMsg.isMsgShow" :severity="showMsg.severity">{{showMsg.content}}</InlineMessage>
            </div>
        </div>
    </div>

    <Dialog v-model:visible="showEditModuleDialog" :style="{width: '28rem'}" :modal="true">
        <template #header>
            <h5 style="margin: 0px">Edit system module</h5>
        </template>

        <SystemModule @RefreshModulePermission="getPermissionList" />
    </Dialog>
</div>
</template>

<script>
import { ref, reactive, onMounted, watchEffect, provide, inject } from 'vue'
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';

import UserRole from '../components/UserRole.vue';
import SystemModule from '../components/SystemModule.vue';
import ModulePermission from '../service/ModulePermission';
import SystemModuleService from '../service/SystemModuleService';

export default {
    props: {
        ShowAssignButtonRoleList: Boolean
    },
    emits: ['RefreshUserAccountList'],
    setup(props, {emit}) {
        const { can } = useAbility()
        const toast = useToast()
        const confirm = useConfirm()
        const loading = ref(false)
        const showMsg = ref({})

        const userRole = reactive({ id: 0 })
        provide('userRole', userRole)
        const userIs = inject('userIs')

        let permission = ref([])
        let updatePermissionData = ref([])
        const showUpdatePermissionButton = ref(false)
  
        const showEditModuleDialog = ref(false);

        //On Create
        const modulePermission = ref(new ModulePermission())
        const systemModuleService = ref(new SystemModuleService())
        
        onMounted(() => {
            permission.value.length = 0
        })        

        watchEffect((onInvalidate) => {
            if(userRole.id != 0) {
                getPermissionList()
                
                //Cancel previous request if role ID changed
                onInvalidate(() => {
                    modulePermission.value.cancelRequest()
                    
                    getPermissionList(true)
                })
            }
        })

        const editModuleDialog = () =>{
            showEditModuleDialog.value = !showEditModuleDialog.value
        }

        function getPermissionList (clearTimeOut) {
            showUpdateButton(false)
            // if(updatePermissionData.value.length > 0)
            //     updatePermissionData.value.length = 0 //Clear update permission data array

            const waitAPI = setTimeout(() => {
                setLoading(true)
            }, 800)

            if(clearTimeOut == true) {
                clearInterval(waitAPI)
            } else {
                modulePermission.value.getModulePermission(userRole.id).then((data) =>{
                    if(!data.errorResponse) {
                        if(userIs.type != 'Administrator') {
                            data = data.filter(item => item.module_name != 'Role Management')
                        }
                        permission.value = data

                        clearInterval(waitAPI)
                        setLoading(false)
                    } else {
                        clearInterval(waitAPI)
                        setLoading(false)
                    }
                    if(data.status == 404) {
                        permission.value.length = 0
                    }
                });
            }
        }

        const updatePermission = () => {
            modulePermission.value.updatePermission(updatePermissionData.value).then((data) => {
                if(data.errorResponse){
                    showMessage('error', data.errorResponse, 5000)
                } else {
                    updatePermissionData.value.length = 0 //Clear update permission data array
                    getPermissionList()
                    showUpdateButton(false)
                    showMessage('success', 'Permission updated successfully.', 3000)
                }
            })
        }

        const confirmDeletePermission = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.module_name + '" module and its permission?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const module_id = data.module_id
                    systemModuleService.value.deleteSystemModule(module_id).then((data) => {
                        if(!data.errorResponse){
                            permission.value = permission.value.filter(val => val.module_id !== module_id);
                        } else {
                            toast.add({icon: 'pi-times-circle', severity:'error', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});        
                        }
                    })
                }
            });
        }
        
        const onCheckBoxChanged = (data, dataindex) =>{
            if (showUpdatePermissionButton.value == false) showUpdateButton(true)
            const index = updatePermissionData.value.findIndex(val => val.idx == dataindex)
            if(index == -1) {
                updatePermissionData.value = [...updatePermissionData.value, Object.assign(data, {idx: dataindex, role_id: userRole.id})]
            }
        }

        const showMessage = (severity, msgContent, life) => {
            showMsg.value = {
                isMsgShow: true, 
                severity: severity, 
                content: msgContent
            }

            setTimeout(() => {
                showMsg.value = {}
            }, life)
        }

        const setLoading = (status) => {
            loading.value = status
        }

        function showUpdateButton(isShow) {
            showUpdatePermissionButton.value = isShow
        }

        const emitRefreshUser = () =>  {
            emit('RefreshUserAccountList')
        }

        return {
            can,
            emitRefreshUser,

            loading,
            permission,

            showMsg,

            editModuleDialog,
            showEditModuleDialog,

            onCheckBoxChanged,
            showUpdatePermissionButton,
            updatePermission,
            confirmDeletePermission,

            getPermissionList,
            userRole,
            userIs,
        }
    },
    components: {
        UserRole,
        SystemModule,
    }
}
</script>

<style lang="scss" scoped>
.option-bar {
    display: flex;
}

.permission-datatable {
    min-height: 380px;
}

.datatable-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
<template>
    <InlineMessage v-if="requestError.isError" severity="warn" class="p-ml-4">{{ requestError.warnMessage }}</InlineMessage>
    <DataTable
        :value="systemModuleDataTable"
        responsiveLayout="scroll"
        scrollHeight="245px"
        selectionMode="single"
        v-model:selection="selectedSystemModuleDataTable"
        @rowSelect="onRowSelect"
        dataKey="id"
        :loading="loadingSystemModuleList"
    >
        <Column field="id" header="ID" :style="{'width':'80px', 'min-width':'60px'}"></Column>
        <Column field="module_name" header="Module name" :style="{'min-width':'30px'}"></Column>

        <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
            <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="editSystemModule(slotProps.data)"/>
                <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteModule(slotProps.data)" />
            </template>
        </Column>
    </DataTable>

    <div class="modulelist-input-bar">
        <form @submit.prevent="form_type == 'add' ? addModule() : updateModule()">
            <InputText v-model="systemmodule_form.module_name" id="module_name" type="text" placeholder="Module name" :class="{'p-invalid':v$.module_name.$error}"/>

                <Button v-if="form_type == 'add'" label="Add" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
                <Button v-if="form_type == 'update'" label="Update" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
        </form>
    </div>
</template>

<script>
import { ref, reactive, computed, onMounted } from 'vue'
import { useConfirm } from "primevue/useconfirm";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import SystemModuleService from '../service/SystemModuleService';

export default {
    emits: ['RefreshModulePermission'],
    setup(props, { emit }) {
        const confirm = useConfirm()
            
        let loadingSystemModuleList = ref(false) 
        let systemModuleDataTable = ref([])
        let selectedSystemModuleDataTable = ref()        
        
        let form_type = ref('add')
        let systemmodule_form = reactive({})
        const module_id = ref(0)
        let old_modulename = ''
        let requestError =  reactive({
            isError: false,
            warnMessage: ''
        })
        
        //On Create
        const systemModuleService = ref(new SystemModuleService())

        //On Mounted
        onMounted(() => {
            refreshModuleList()
        })

        //Method
        const initFormData = () => {
            const initForm = {
                module_name: '',
            }

            Object.assign(systemmodule_form, initForm)
        }

        const onRowSelect = (event) =>  {
            systemmodule_form.module_name = event.data.module_name
        }

        const editSystemModule = (module_data) => {
            form_type.value = 'update'
            selectedSystemModuleDataTable.value = module_data
            module_id.value = module_data.id

            old_modulename = module_data.module_name
            systemmodule_form.module_name = module_data.module_name
        }

        const addModule = () => {
            if(validateForm()){
                systemModuleService.value.addSystemModule(systemmodule_form).then((data) =>{
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            showRequestError(true, data.message)
                        } else {
                            refreshModuleList()
                            emit('RefreshModulePermission')
                            v$.value.$reset() //Reset validations
                            showRequestError(false, '')
                        }
                    } 
                })
            }
        }

        const updateModule = () => {
            if(validateForm()) {
                if(old_modulename != systemmodule_form.module_name)  {
                    systemModuleService.value.updateSystemModule(module_id.value, systemmodule_form).then((data) =>{
                        if(!data.errorResponse) {
                             if(data.status == 400) {
                                showRequestError(true, data.message)
                            } else {
                                refreshModuleList()
                                emit('RefreshModulePermission')
                                form_type.value = 'add'
                                v$.value.$reset() //Reset validations
                                showRequestError(false, '')
                            }
                        } 
                    })
                }
            }
        }

        const refreshModuleList = () =>{
            initFormData()
            loadingSystemModuleList.value = true
            systemModuleService.value.getSystemModule().then((data) => {
                systemModuleDataTable.value = data
                
                loadingSystemModuleList.value = false
            })
        }

        const confirmDeleteModule = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.module_name +'" module?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const module_id = data.id
                    systemModuleService.value.deleteSystemModule(module_id).then((data) => {
                        if(!data.errorResponse){
                            refreshModuleList()
                            emit('RefreshModulePermission')
                        }
                    })
                }
            });
        }

        const showRequestError = (isError, warnMessage) => {
            requestError.isError = isError
            requestError.warnMessage = warnMessage

            setTimeout(() => {
                requestError.isError = false
                requestError.warnMessage = ''
            }, 4000);
        }

        //Form Validations
        let rules = computed(() =>  {
            return {
                module_name: { required },
            }
        })
        const v$ = useVuelidate(rules, systemmodule_form)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            v$,
            loadingSystemModuleList,
            onRowSelect,
            systemModuleDataTable,
            selectedSystemModuleDataTable,
            form_type,
            systemmodule_form,
            requestError,

            editSystemModule,
            refreshModuleList,
            addModule,
            updateModule,
            confirmDeleteModule,
        }
    },
}
</script>

<style lang="scss" scoped>
.module-bar {
    margin-top: 5px;

    label {
        font-weight: 500;
        width: 90px;
        // margin: 0;
        display: flex;
        align-items: center;
    }
}

.modulelist-input-bar {
    margin-top: 1.2rem;
}

.modulelist-input-bar .p-inputtext{
    width: 18rem;
    margin-right: .8rem;
}

.small-datatable-button {
    width: 28px;
}
.small-datatable-button.p-button-icon-only.p-button-rounded {
    height: 28px;
}
</style>
import axios from '../axios.instance'

export default class RoleService {
    async getRoleName(){
        try {
            const res = await axios.get('/role')
            // console.log(res)
            return res.data
        } catch (error) {
            return error.response.data
        }
    }

    async addRoleName(data) {
        try {
            if(data != ''){
                const res = await axios.post('/role', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateRoleName(role_id, data) {
        try {
            if(data != ''){
                const res = await axios.put('/role/'+role_id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteRoleName(role_id) {
        try {
            if(role_id != 0) {
                const res = await axios.delete('/role/'+role_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async assignRole(data) {
        try {
            if(data != ''){
                const res = await axios.post('/useraccount/assignrole', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}
<template>
    <div class="rolelist-bar">
        <div class="p-grid">
            <label for="role_name" class="p-col-fixed">User Role:</label>
            <div class="p-col-fixed" style="padding: 0">
                <Dropdown style="width: 20em" v-model="selectedRoleDropdown" @change="onDropdownChanged()" :options="userRoleDropDown" optionLabel="role_name" placeholder="Select user role">
                </Dropdown>
                <span v-if="can('create', 'role') || can('update', 'role')">
                    <Button v-if="EditRoleButton" icon="pi pi-bars" @click="showRoleEditDialog" class="p-ml-3 pi-plus-resized p-button-secondary" style="width: 25px; height: 25px; margin: 3px" /> 
                </span>
                <span v-if="can('create', 'user account') || can('update', 'user account')">
                    <Button v-if="AssignRoleButton" label="Assign Role" class="p-ml-3 p-button-sm p-button-warning" @click="assignUserRole()" :disabled="isSameRole"/>
                </span>
            </div>
            <!-- Show message when assigned permission successfully -->
            <InlineMessage class="p-ml-3" v-if="showMsg.isMsgShow" :severity="showMsg.severity">{{showMsg.content}}</InlineMessage>
        </div>
    </div>

    <Dialog v-model:visible="isRoleEditDialog" :style="{width: '28rem'}" :modal="true">
        <template #header>
            <h5 v-if="!requestError.isError" style="margin: 0px">Update Role List</h5>
            <InlineMessage v-if="requestError.isError" severity="warn" class="p-ml-4">{{ requestError.warnMessage }}</InlineMessage>
        </template>
    
        <DataTable
            :value="userRoleDataTable"
            responsiveLayout="scroll"
            scrollHeight="245px"
            selectionMode="single"
            v-model:selection="selectedRoleDataTableRow"
            @rowSelect="onRowSelect"
            dataKey="id"
            :loading="loadingUserRoleList"
        >
            <Column field="id" header="ID" :style="{'width':'80px', 'min-width':'30px'}"></Column>
            <Column field="role_name" header="Role name" :style="{'min-width':'60px'}"></Column>

            <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="editRoleName(slotProps.data)"/>
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteUserRole(slotProps.data)" />
                </template>
            </Column>
        </DataTable>

        <div class="rolelist-input-bar">
            <form @submit.prevent="form_type == 'add' ? addRoleName() : updateRoleName()">
                <InputText v-model="role_form.role_name" id="role_name" type="text" placeholder="Role name" :class="{'p-invalid':v$.role_name.$error}"/>

                    <Button v-if="form_type == 'add'" label="Add" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
                    <Button v-if="form_type == 'update'" label="Update" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
            </form>
        </div>
    </Dialog>
</template>

<script>
import { ref, reactive, onMounted, computed, watchEffect, inject } from 'vue';
import { useStore } from 'vuex';
import { useConfirm } from "primevue/useconfirm";
import { useAbility } from '@casl/vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import RoleService from '../service/RoleService';

export default {
    props: {
        EditRoleButton: Boolean,
        AssignRoleButton: Boolean
    },
    emits: ['EmitRefreshUserAccount'],
    setup(props, { emit }) {
        const { can } = useAbility()
        const confirm = useConfirm()
        const store = useStore()
        const showMsg = ref({})
        let requestError =  reactive({
            isError: false,
            warnMessage: ''
        })
        const user_roleid = computed(() => store.state.userAccount.user_roleid)

        //INJECT ROLE ID TO PROVIDER IN ROLE MGT COMPONENT
        const userRole = inject('userRole')

        const isSameRole = ref(true)

        let userRoleDropDown = ref([])
        let selectedRoleDropdown = ref('')
            
        let loadingUserRoleList = ref(false) 
        let userRoleDataTable = ref([])
        let selectedRoleDataTableRow = ref()        
        
        const isRoleEditDialog = ref(false)
        let form_type = ref('')
        let role_form = reactive({})
        const role_id = ref(0)
        let old_rolename = ''
        const userIs = inject('userIs')
        
        //On Create
        const roleService = ref(new RoleService())

        //On Mounted
        onMounted(() => {
            setUserRoleDropDown()

            assignRoleButton(true)
        })

        //Method
        const initFormData = () => {
            const initForm = {
                role_name: '',
            }

            Object.assign(role_form, initForm)
        }

        watchEffect(() => {
            if(user_roleid.value != 0) {
                const index = userRoleDropDown.value.findIndex(val => val.id == user_roleid.value)
                selectedRoleDropdown.value = userRoleDropDown.value[index]
                
                assignRoleButton(true) //Disable Assign Role button when role id changed
            }

            if(user_roleid.value == '')  {
                assignRoleButton(false)
            }
        })

        //Watch dropdown value changed
        watchEffect(() => { 
            if(selectedRoleDropdown.value) {
                userRole.id = selectedRoleDropdown.value.id
            }
        })

        const setUserRoleDropDown = () => {
            roleService.value.getRoleName().then((data) => {
                if(userIs.type != 'Administrator') {
                    data = data.filter(item => item.role_name != 'Administrator')
                }
                
                userRoleDropDown.value = data

                selectedRoleDropdown.value = userRoleDropDown.value[0]
                // store.dispatch('permission_RoleID', userRoleDropDown.value[0].id)
            })
        }

        const onRowSelect = (event) =>  {
            role_form.role_name = event.data.role_name
        }

        const showRoleEditDialog = () => {
            form_type.value = 'add'
            isRoleEditDialog.value = !isRoleEditDialog.value
            
            refreshRoleList()
        }

        const editRoleName = (role_data) => {
            form_type.value = 'update'
            selectedRoleDataTableRow.value = role_data
            role_id.value = role_data.id

            old_rolename = role_data.role_name
            role_form.role_name = role_data.role_name
        }

        const addRoleName = () => {
            if(validateForm()) {
                roleService.value.addRoleName(role_form).then((data) =>{
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            showRequestError(true, data.message)
                        } else {
                            refreshRoleList()
                            v$.value.$reset() //Reset validations
                            showRequestError(false, '')
                        }
                    } 
                })
            }
        }

        const updateRoleName = () => {
            if(validateForm()) {
                if(old_rolename != role_form.role_name)  {
                    roleService.value.updateRoleName(role_id.value, role_form).then((data) =>{
                        if(!data.errorResponse) {
                            if(data.status == 400) {
                                showRequestError(true, data.message)
                            } else {
                                refreshRoleList()
                                form_type.value = 'add'
                                v$.value.$reset() //Reset validations
                                showRequestError(false, '')
                            }
                        } 
                    })
                }
            }
        }

        const showRequestError = (isError, warnMessage) => {
            requestError.isError = isError
            requestError.warnMessage = warnMessage

            setTimeout(() => {
                requestError.isError = false
                requestError.warnMessage = ''
            }, 4000);
        }

        const refreshRoleList = () =>{
            initFormData()
            loadingUserRoleList.value = true
            roleService.value.getRoleName().then((data) => {
                userRoleDataTable.value = data
                
                loadingUserRoleList.value = false
            })
        }

        const onDropdownChanged = () =>{
            // store.dispatch('permission_RoleID', selectedRoleDropdown.value.id)

            if(props.AssignRoleButton == true && user_roleid.value != 0) {
                if(selectedRoleDropdown.value.id == user_roleid.value) {
                    assignRoleButton(true)
                } else {
                    assignRoleButton(false)
                }
            }
        }

        const confirmDeleteUserRole = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.role_name +'" role?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const role_id = data.id
                    roleService.value.deleteRoleName(role_id).then((data) => {
                        if(!data.errorResponse){
                            refreshRoleList()
                        }
                    })
                }
            });
        }

        const assignUserRole = () => {
            const userID = store.state.userAccount.user_account.id
            const roleID = selectedRoleDropdown.value.id
            
            const roleData = {
                useraccount_id: userID,
                role_id: roleID
            }

            roleService.value.assignRole(roleData).then((data) => {
                if(!data.errorResponse) {
                    emit('EmitRefreshUserAccount')
                    // store.dispatch('user_roleID', roleID) //userAccount Store
                    showMessage('success', 'Permission assigned successfully.', 3000)
                    assignRoleButton(true)
                } else {
                    showMessage('error', data.errorResponse, 4000)
                }
            })
        }

        function assignRoleButton(value) {
            isSameRole.value = value
        }

        const showMessage = (severity, msgContent, life) => {
            showMsg.value = {
                isMsgShow: true, 
                severity: severity, 
                content: msgContent
            }

            setTimeout(() => {
                showMsg.value = {}
            }, life)
        }

        //Form Validations
        let rules = computed(() =>  {
            return {
                role_name: { required }
            }
        })
        const v$ = useVuelidate(rules, role_form)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            can,
            v$,
            userRoleDropDown,
            selectedRoleDropdown,
            onDropdownChanged,
            isSameRole,
            showMsg,
            requestError,

            loadingUserRoleList,
            onRowSelect,
            userRoleDataTable,
            selectedRoleDataTableRow,
            form_type,
            role_form,
            
            editRoleName,
            addRoleName,
            updateRoleName,
            confirmDeleteUserRole,
            assignUserRole,

            showRoleEditDialog,
            isRoleEditDialog,
        }
    },
}
</script>

<style lang="scss" scoped>
.rolelist-bar {
    margin-top: 5px;

    label {
        font-weight: 500;
        width: 90px;
        // margin: 0;
        display: flex;
        align-items: center;
    }
}

.rolelist-input-bar {
    margin-top: 1.2rem;
}

.rolelist-input-bar .p-inputtext{
    width: 18rem;
    margin-right: .8rem;
}

.small-datatable-button {
    width: 28px;
}
.small-datatable-button.p-button-icon-only.p-button-rounded {
    height: 28px;
}
</style>